import * as React from 'react';
import { NextPage } from 'next';
import Router from 'next/router';

import { isAbsoluteURL } from 'src/common/URLUtils';
import { transformBasename } from 'src/common/utils/transformBasename';
import { State } from 'src/global/store';

import { getDisplayName } from '../../helpers/getDisplayName';

interface Options {
  // if validator return false, request will be redirect to redirectHref
  validator: (config: any, state: State) => boolean;
  redirectHref: string;
  redirectAsPath?: string;
}

/**
 * This HOC creator can only do simple redirection,
 * if generating the destination requires data from the original request,
 * please use createWithRedirectDestination instead
 */
export const createWithRedirect =
  ({ validator, redirectHref, redirectAsPath }: Options) =>
  <P extends Record<string, unknown>>(Page: NextPage<P>) => {
    const WithRedirect = (props: P) => {
      return <Page {...props} />;
    };

    WithRedirect.getInitialProps = async (ctx: any) => {
      const location = redirectAsPath || redirectHref;
      const isExternalURL = isAbsoluteURL(location);

      // redirect at server
      if (ctx.req) {
        const clientConfig = ctx.req.dstContext.clientConfig;
        const reduxState = ctx.reduxStore.getState();
        if (!validator(clientConfig, reduxState)) {
          const Location = isExternalURL
            ? location
            : transformBasename(clientConfig.BASENAME) + location;

          ctx.res.writeHead(302, {
            Location,
          });
          ctx.res.end();
          return {};
        }
      }

      // redirect at client
      if (!ctx.req) {
        const clientConfig = window.__NEXT_DATA__.props.dstContext.clientConfig;
        const reduxState = window.__NEXT_REDUX_STORE__.getState();
        if (!validator(clientConfig, reduxState)) {
          // Router is patched, BASENAME will be prefixed there
          if (!isExternalURL) {
            Router.push(redirectHref, redirectAsPath);
          } else {
            window.location.href = location;
          }
          return {};
        }
      }

      // no need to redirect, return initialProps if needed
      if (Page.getInitialProps) {
        const pageProps = await Page.getInitialProps(ctx);
        return pageProps;
      }

      return {};
    };

    WithRedirect.displayName = `WithRedirect${getDisplayName(Page)}`;

    return WithRedirect;
  };
