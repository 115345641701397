import React from 'react';

import { InfoBox } from './InfoBox/InfoBox';

export const FieldsRequiredInfoBox = () => (
  <InfoBox
    infoMessage={{
      id: 'edit-expert-modal-info_fields-required-optional',
      defaultMessage: 'All fields are required unless indicated as optional.',
    }}
  />
);
