/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { ROUTES } from 'src/common/routes';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';

export function withExpertLogin<P = Record<string, unknown>>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<unknown>>
) {
  const Wrapper = (props: P) => {
    const router = useRouter();
    const isAuthenticated = useSelector(getIsAuthenticated);

    useEffect(() => {
      if (!isAuthenticated) {
        router.push({
          pathname: `/${ROUTES.expertLogin}`,
          query: {
            ...router.query,
            next: router.pathname,
            nextAs: router.asPath,
          },
        });
      }
    }, [isAuthenticated]);

    if (!isAuthenticated) return null;
    return <WrappedComponent {...props} />;
  };
  return Wrapper;
}
