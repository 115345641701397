import { Greyscale, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const ApplicationDataGridLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  min-width: 241px;
  color: ${Greyscale.black};
`;

const SMALL_SCREEN_LABEL_VALUE_GAP = 8;
export const ApplicationDataGrid = styled.div`
  font-size: 12px;
  line-height: 16px;
  align-items: baseline;
  display: grid;
  grid-template-columns: 100%;
  overflow-wrap: anywhere;
  gap: ${SMALL_SCREEN_LABEL_VALUE_GAP}px;

  ${ApplicationDataGridLabel} {
    margin-top: ${32 - SMALL_SCREEN_LABEL_VALUE_GAP}px;
  }
  ${ApplicationDataGridLabel}:first-child {
    margin-top: 0;
  }
  @media (min-width: ${ScreenSize.desktopS}px) {
    column-gap: 30px;
    row-gap: 23px;
    grid-template-columns: max-content 1fr;
    ${ApplicationDataGridLabel} {
      margin-top: 0;
    }
  }
`;

export const ApplicationDataGridTextValue = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Greyscale.black};
`;

export const ApplicationDataGridEmptyValue = styled(
  ApplicationDataGridTextValue
)`
  color: ${Greyscale.grey};
`;
