import { sortBy } from 'lodash';
import { defaultMemoize } from 'reselect';

export function generateUsername(
  input: { lastName?: string; firstName?: string; email?: string },
  firstNameOnly = false,
  isNameEdit = false
) {
  if (!input) {
    return '(Unnamed User)';
  }

  if (input.lastName && !isNameEdit) {
    input.lastName = input.lastName.toUpperCase();
  }

  if (input.lastName && input.firstName && !firstNameOnly) {
    return [input.lastName, input.firstName].join(' ');
  } else if (input.firstName) {
    return input.firstName;
  } else if (input.lastName) {
    return input.lastName;
  } else {
    return input.email;
  }
}

export function joinAttributes(
  attributes: string[],
  delimiter = ', ',
  fallback = '-'
) {
  const truthyAttributes = attributes.filter((attribute) => Boolean(attribute));
  if (truthyAttributes.length) {
    return truthyAttributes.join(delimiter);
  } else {
    return fallback;
  }
}

export function urlFilename(value = '') {
  try {
    if (
      (!value.startsWith('http://') || !value.startsWith('https://')) &&
      value.startsWith('www.')
    ) {
      value = `http://${value}`;
    }
    const url = new URL(value);
    return url.pathname.substring(1);
  } catch (err) {
    if (value.startsWith('@')) {
      value = value.substring(1);
    }
    return value;
  }
}

export function removeAtSymbol(username: string) {
  return String(username).replace('@', '');
}

export const formatNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Adds additional label and value fields for use with react-selectize-glints
export function remapValuesForSelectize(input: $TSFixMe) {
  if (Array.isArray(input)) {
    return input
      .filter((item) => item)
      .map((item) => {
        const option = {
          ...item,
          label: item.label || item.name,
          value: item.id || item.code,
        };
        return option;
      });
  } else if (input) {
    const temp = {
      ...input,
      label: input.name,
      value: input.id || input.code,
    };
    return temp;
  }
}

export function trimCommaAndDot(string: string) {
  return string.replace(/[,.]/g, '');
}

export function includeWhiteSpace(string: string) {
  return /\s/.test(string);
}

export function isAllWhiteSpace(string: string) {
  if (string === '') {
    return false;
  }
  return string.trim().length === 0;
}

export const memoizedExperiencesSort = defaultMemoize(
  (experiences: $TSFixMe[] = []) => {
    const experiencesToSort = experiences.map(
      ({ id, startDate, endDate, isPresent }) => ({
        id,
        startDate: new Date(startDate),
        endDate: isPresent ? new Date() : new Date(endDate),
      })
    );
    const sortedExperiences = sortBy(experiencesToSort, [
      'endDate',
      'startDate',
    ]);
    return sortedExperiences
      .map((sortedExperience) =>
        experiences.find((experience) => experience.id === sortedExperience.id)
      )
      .slice()
      .reverse();
  }
);
