import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ApplicationDataGridEmptyValue } from './ApplicationDataGrid.sc';

type Props = {
  testId?: string;
};
export const ApplicationDataGridEmptyColumn = ({ testId }: Props) => (
  <ApplicationDataGridEmptyValue data-testid={testId}>
    <FormattedMessage
      id="expert-application.empty-value"
      defaultMessage="No details to show"
    />
  </ApplicationDataGridEmptyValue>
);
