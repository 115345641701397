import React from 'react';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';

import { InfoBoxContainer, InfoIcon } from './InfoBox.sc';

type InfoProps = {
  infoMessage: FormattedMessageProps;
};

export const InfoBox = ({ infoMessage }: InfoProps) => {
  return (
    <InfoBoxContainer>
      <InfoIcon />
      <FormattedMessage {...infoMessage} />
    </InfoBoxContainer>
  );
};
