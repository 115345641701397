import React, { useState } from 'react';
import { parsePhoneNumber } from 'awesome-phonenumber';
import {
  CallingCodeOption,
  PhoneNumber as PhoneNumberType,
  PhoneNumberInput as BasePhoneNumberInput,
} from 'glints-aries';
import { flatMap, identity, isString, uniqBy } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import useSWR from 'swr';

import { Country } from 'src/global/models';
import { CALLING_CODES } from 'src/modules/Settings/Constants';

import { countryCodeAndNameMap, CountryCodes } from './enums';
import { useDebounce } from './hooks/useDebounce';

interface Props {
  className?: string;
  onChange: (value: PhoneNumberType) => void;
  onFocus?: () => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value: PhoneNumberType;
  error?: string;
  addon?: React.ReactElement;
}

const offlineCallingCodeLabels: Record<number, string> = {
  60: 'Malaysia',
  62: 'Indonesia',
  63: 'Philippines',
  65: 'Singapore',
  66: 'Thailand',
  84: 'Vietnam',
  852: 'Hong Kong',
  886: 'Taiwan',
};

const featuredCountryCodes = ['MY', 'ID', 'SG', 'TW', 'VN'];

const addFallbackOptionIfGivenCallingCodeIsntKnown = (
  value: PhoneNumberType,
  callingCodeOptions: CallingCodeOption[],
  fallbackOptionLabel: string
) => {
  const isCurrentCountryCodeAnOption = callingCodeOptions.find(
    (callingCodeOption) => value.callingCode === callingCodeOption.callingCode
  );
  const unknownCallingCodeFallback = {
    callingCode: value.callingCode,
    label: offlineCallingCodeLabels[value.callingCode] || fallbackOptionLabel,
    isFeatured: true,
  };

  return [
    ...callingCodeOptions,
    ...(isCurrentCountryCodeAnOption ? [] : [unknownCallingCodeFallback]),
  ];
};

const getKey = (filterInput = '') => {
  const params = new URLSearchParams({
    where: JSON.stringify(
      filterInput
        ? {
            name: { ilike: `%${filterInput}%` },
          }
        : { code: { in: featuredCountryCodes } }
    ),
  });
  return `countries?${params.toString()}`;
};

const useCountryCallingCodeOptions = (filterInput: string) => {
  const filterInputDebounced = useDebounce(filterInput, 200);
  const { data, error, isValidating } = useSWR<{ data: Country[] }>(
    getKey(filterInputDebounced)
  );
  const countryCodeOptionsAreLoading = (!data && !error) || isValidating;
  const countries = data?.data;
  const callingCodes = countries
    ? uniqBy(
        flatMap(
          countries,
          (country) =>
            country.callingCode?.map((callingCode) => {
              if (isString(callingCode)) {
                return {
                  countryName: country.name,
                  countryCode: country.code,
                  code: callingCode.replace(/\+/g, ''),
                };
              } else {
                return {
                  countryName: countryCodeAndNameMap[CountryCodes.ID],
                  countryCode: CountryCodes.ID,
                  code: CALLING_CODES[CountryCodes.ID].replace(/\+/g, ''),
                };
              }
            })
        ).filter(identity),
        'countryCode'
      )
    : [];
  const callingCodeOptions: CallingCodeOption[] = callingCodes.map(
    (callingCode) => ({
      label: callingCode.countryName,
      callingCode: Number(callingCode.code),
      isFeatured: featuredCountryCodes.includes(callingCode.countryCode),
    })
  );

  return { callingCodeOptions, countryCodeOptionsAreLoading } as const;
};

const messages = defineMessages({
  label: {
    id: 'events.signup.form.mobile.number',
    defaultMessage: 'Mobile Number',
  },
  featuredOptionsLabel: {
    id: 'events.signup.form.phoneNumberInput.featuredOptionsLabel',
    defaultMessage: 'Frequently Used',
  },
  otherOptionsLabel: {
    id: 'events.signup.form.phoneNumberInput.otherOptionsLabel',
    defaultMessage: 'The Rest Of The World',
  },
  callingCodeFilterInputPlaceholder: {
    id: 'events.signup.form.phoneNumberInput.callingCodeFilterInputPlaceholder',
    defaultMessage: 'Type country code or country name',
  },
  callingCodeNoOptionsLabel: {
    id: 'events.signup.form.phoneNumberInput.callingCodeNoOptionsLabel',
    defaultMessage:
      'Sorry, there are no results for country {searchTerm}. Please try again.',
  },
  currentCountry: {
    id: 'events.signup.form.phoneNumberInput.currentCountry',
    defaultMessage: 'Current Country',
  },
});

export const PhoneNumberInput = ({
  onChange,
  value,
  className,
  error,
  onFocus,
  onBlur,
  addon,
}: Props) => {
  const { formatMessage } = useIntl();

  const [filterInput, setFilterInput] = useState<string>('');

  const { callingCodeOptions, countryCodeOptionsAreLoading } =
    useCountryCallingCodeOptions(filterInput.trim().toLowerCase());

  const options = addFallbackOptionIfGivenCallingCodeIsntKnown(
    value,
    callingCodeOptions,
    formatMessage(messages.currentCountry)
  );

  return (
    <BasePhoneNumberInput
      addon={addon}
      value={value}
      callingCodeOptions={options}
      filterValue={filterInput}
      isLoadingCallingCodeOptions={countryCodeOptionsAreLoading}
      label={formatMessage(messages.label)}
      featuredOptionsLabel={formatMessage(messages.featuredOptionsLabel)}
      otherOptionsLabel={formatMessage(messages.otherOptionsLabel)}
      callingCodeFilterInputPlaceholder={formatMessage(
        messages.callingCodeFilterInputPlaceholder
      )}
      callingCodeNoOptionsLabel={formatMessage(
        messages.callingCodeNoOptionsLabel,
        { searchTerm: filterInput }
      )}
      error={error}
      onChange={(value) => onChange(value)}
      onInputChange={(filterInput) => setFilterInput(filterInput || '')}
      onFocus={onFocus}
      onBlur={onBlur}
      {...{ className }}
    />
  );
};

export const isNotValidPhoneNumber = (phone: string) => {
  if (!phone) return true;
  return !parsePhoneNumber(phone).valid;
};
