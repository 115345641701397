/* eslint-disable no-useless-escape */
import { listOfSocialNetworkName } from './constants';

export function isValidURL(urlInput: string) {
  try {
    const urlWithHttpPrefix = prefixUrlWithHttp(urlInput);
    const validUrl = new URL(urlWithHttpPrefix);
    return Boolean(validUrl.href);
  } catch (err) {
    return false;
  }
}

function prefixUrlWithHttp(url: string) {
  if (
    !(url.startsWith('http://') || url.startsWith('https://')) &&
    url.startsWith('www.')
  ) {
    return `http://${url}`;
  } else {
    return url;
  }
}

export const isAbsoluteURL = (url: string) => {
  const isAbsolute = new RegExp('^(?:[a-z]+:)?//', 'i');
  return isAbsolute.test(url);
};

export const testSocialMediaURLPattern = (
  string: string,
  socialNetworkName: string
) => {
  const socialWebURL =
    /^(http:\/\/www\.|https:\/\/www\.|www\.|https:\/\/|http:\/\/)([a-z0-9]+)\.[a-z]{2,5}\/[A-Za-z0-9\-\.\_\?\=\/]{1,}$/;
  const linkedInRegExp =
    /^(http:\/\/www\.|https:\/\/www\.|www\.|https:\/\/|http:\/\/)([a-z0-9]+)\.[a-z]{2,5}\/(in)\/[A-Za-z0-9\-\.\_\?\=\/]{1,}$/;
  const socialWebWithAtURLRegExp =
    /^(http:\/\/www\.|https:\/\/www\.|www\.|https:\/\/|http:\/\/)([a-z0-9]+)\.[a-z]{2,5}\/\@[A-Za-z0-9\-\.\_\?\=\/]{1,}$/;

  switch (socialNetworkName) {
    case listOfSocialNetworkName.LinkedIn:
      return linkedInRegExp.test(string);
    case listOfSocialNetworkName.Tiktok:
    case listOfSocialNetworkName.Youtube:
      return socialWebWithAtURLRegExp.test(string);
    default:
      return socialWebURL.test(string);
  }
};

const profileURLRegExpGenerator = (website: string) => {
  const urlRegExp = `^(https?:\/\/)?(www\.)?${website}[a-zA-Z0-9(_\.\?)?]`;
  return new RegExp(urlRegExp);
};

const socialNetworkRegExp = {
  [listOfSocialNetworkName.Facebook]: profileURLRegExpGenerator(
    'facebook.com|fb.com/'
  ),
  [listOfSocialNetworkName.Twitter]: profileURLRegExpGenerator('twitter.com/'),
  [listOfSocialNetworkName.Instagram]:
    profileURLRegExpGenerator('instagram.com/'),
  [listOfSocialNetworkName.LinkedIn]:
    profileURLRegExpGenerator('linkedin.com/in/'),
  [listOfSocialNetworkName.Behance]: profileURLRegExpGenerator('behance.net/'),
  [listOfSocialNetworkName.GitHub]: profileURLRegExpGenerator('github.com/'),
  [listOfSocialNetworkName.Tiktok]: profileURLRegExpGenerator('tiktok.com/@'),
  [listOfSocialNetworkName.CodePen]: profileURLRegExpGenerator('codepen.io/'),
  [listOfSocialNetworkName.Vimeo]: profileURLRegExpGenerator('vimeo.com/'),
  [listOfSocialNetworkName.Youtube]: profileURLRegExpGenerator('youtube.com/@'),
};

export const testSocialMediaProfileURL = (
  url: string,
  socialNetworkName: string
) => socialNetworkRegExp[socialNetworkName].test(url);
