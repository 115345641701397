import { defineMessages } from 'react-intl';

const tabMessages = defineMessages({
  details: {
    id: 'login.details',
    defaultMessage: 'Login Details',
  },
  contact: {
    id: 'contact.information',
    defaultMessage: 'Contact Information',
  },
  linked: {
    id: 'linked.accounts',
    defaultMessage: 'Linked Accounts',
  },
  notifications: {
    id: 'notification.preferences',
    defaultMessage: 'Notification Preferences',
  },
  help: {
    id: 'help.and.support',
    defaultMessage: 'Help & Support',
  },
});

export const TABS = {
  details: {
    key: 'details',
    to: '/settings/details',
    message: tabMessages.details,
  },
  contact: {
    key: 'contact',
    to: '/settings/contact',
    message: tabMessages.contact,
  },
  linked: {
    key: 'linked',
    to: '/settings/linked',
    message: tabMessages.linked,
  },
  notifications: {
    key: 'notifications',
    to: '/settings/notifications',
    message: tabMessages.notifications,
  },
  help: {
    key: 'help',
    to: '/settings/help',
    message: tabMessages.help,
  },
};

export enum IDENTITY_TYPES {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
}

export const ERRORS = {
  status409: {
    IdentityExists: 'The identity already exists.',
  },
};

export const CALLING_CODES = {
  SG: '+65',
  MY: '+60',
  ID: '+62',
  VN: '+84',
};
