import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import { isNotValidPhoneNumber } from 'src/common/PhoneNumberInput';

export const required =
  (errorMessage = 'Required') =>
  (value: string) => {
    if (!value || isEmpty(trim(value.toString()))) return errorMessage;
    return undefined;
  };

export const validatePhoneNumber =
  (errorMessage: string) => (value: string) => {
    if (isNotValidPhoneNumber(value)) return errorMessage;
    return undefined;
  };
