import { Greyscale, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Spacing = styled.div<{ value: number }>`
  margin-bottom: ${(props) => props.value}px;
`;

export const ApplicationFormLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  @media (min-width: ${ScreenSize.desktopS}px) {
    flex-direction: row;
  }
`;

export const ApplicationFormValueContainer = styled.div`
  flex-grow: 2;
  width: 100%;
  @media (min-width: ${ScreenSize.desktopS}px) {
    width: fit-content;
  }
`;

export const ApplicationFormProgressContainer = styled.div`
  flex-grow: 0;
  align-self: center;
  @media (min-width: ${ScreenSize.desktopS}px) {
    align-self: initial;
  }
`;

export const ScrollTagretSpacing = styled.div<{
  mainHeaderHeight: number;
}>`
  div:target {
    scroll-margin-top: ${({ mainHeaderHeight }) => mainHeaderHeight + 1}px;
    scroll-snap-margin-top: ${({ mainHeaderHeight }) =>
      mainHeaderHeight + 1}px; /*For older iOS browsers*/
  }
`;

export const HelpText = styled.div`
  margin-top: 8px;
  color: ${Greyscale.grey};
  font-size: 14px;
`;

export const DescriptionText = styled.div`
  font-weight: 400;
  margin: 8px 0px;
  font-size: 14px;
`;
