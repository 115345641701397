import { InfoSolidIcon, PrimaryColor } from 'glints-aries';
import styled from 'styled-components';

export const InfoBoxContainer = styled.div`
  background-color: #d6f4ff;
  border: 1px solid ${PrimaryColor.glintsblue};
  display: flex;
  color: #003a6f;
  gap: 10px;
  align-items: center;
  padding: 12px;
`;

export const InfoIcon = styled(InfoSolidIcon)`
  min-width: 16px;
  min-height: 16px;
  color: ${PrimaryColor.glintsblue};
`;
