import * as React from 'react';
import {
  InfoSolidIcon as AriesInfoSolidIcon,
  PrimaryColor,
  ScreenSize,
  SecondaryColor,
  Typography,
  WarningCircleSolidIcon as AriesWarningCircleSolidIcon,
} from 'glints-aries';
import styled, { css } from 'styled-components';

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: ${({ variant }) =>
    variant === 'error' ? 'rgba(236, 39, 43, 0.1)' : 'rgba(11, 174, 236, 0.1)'};

  @media (min-width: ${ScreenSize.desktopS}px) {
    padding: 15px;
  }
`;

const iconStyles = css`
  width: 15px;
  height: 15px;
  margin-top: 2px;
`;

const InfoSolidIcon = styled(AriesInfoSolidIcon).attrs({
  color: SecondaryColor.actionblue,
})`
  ${iconStyles}
`;

const WarningCircleSolidIcon = styled(AriesWarningCircleSolidIcon).attrs({
  color: PrimaryColor.glintsred,
})`
  ${iconStyles}
`;

const Message = styled(Typography.Paragraph).attrs({
  variant: 'caption',
})`
  flex: 1;
  margin: 0 10px;
`;

export const AlertBox: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  variant,
  message,
}) => (
  <Container className={className} variant={variant}>
    {variant === 'error' ? <WarningCircleSolidIcon /> : <InfoSolidIcon />}
    <Message>{message}</Message>
  </Container>
);

interface ContainerProps {
  variant: 'regular' | 'error';
}

interface Props extends ContainerProps {
  className?: string;
  message: React.ReactNode;
}
