import React, { useCallback, useEffect } from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Button, Modal } from 'glints-aries';
import { isEmpty } from 'lodash';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  cancelMessage,
  saveMessage,
  savingMessage,
} from 'src/common/messages/general';
import {
  hideWhatsappMessagingAction,
  showWhatsappMessagingAction,
} from 'src/modules/Whatsapp/actions';
import {
  hideZendeskMessagingAction,
  showZendeskMessagingAction,
} from 'src/modules/Zendesk/actions';

import { ModalTitle } from './UpsertDataModal.sc';

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-542793243
const typedMemo: <T>(c: T) => T = React.memo;

type TestIds = {
  cancelCta?: string;
  submitCta?: string;
};
type Props<FormValues extends FormikValues> = {
  titleMessage: FormattedMessageProps;
  initialValues: FormValues;
  onSubmit: (value: FormValues) => void | Promise<void>;
  children: React.ReactNode;
  onClose(): void;
  onSubmitError?(error: any): void;
  isOpen: boolean;
  testIds?: TestIds;
  hideFooterCancelCta?: boolean;
};

/**
 * This components bind to Formik form.
 *
 * Please compose this component with form fields component.
 *
 * Since we have some common logic for cancel and submit button, the 2 buttons are included in
 * this component. So in the form components, there should be only form fields
 *
 * Few Notes:
 * - In form components, button element needs to have attribute type="button",
 *   otherwise button defaults to type="submit" and will submit the form on click.
 * - For the form component values, make them immutable value type, since the dirty
 *   check is shallow comparison.
 */
export const UpsertDataModal = typedMemo(function FormikUpsertDataModal<
  FormValues,
>({
  titleMessage,
  initialValues,
  onSubmit,
  onSubmitError,
  children,
  onClose,
  isOpen,
  testIds,
  hideFooterCancelCta = false,
}: Props<FormValues>) {
  const onSubmitAndCloseModal = useCallback(
    async (value: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        await onSubmit(value);
        resetForm();
        onClose();
      } catch (error) {
        console.error(error);
        onSubmitError(error);
      }
    },
    [onClose, onSubmit, onSubmitError]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(hideZendeskMessagingAction());
      dispatch(hideWhatsappMessagingAction());
    } else {
      dispatch(showZendeskMessagingAction());
      dispatch(showWhatsappMessagingAction());
    }

    return function onUnmount() {
      dispatch(showZendeskMessagingAction());
      dispatch(showWhatsappMessagingAction());
    };
  }, [isOpen, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitAndCloseModal}
      enableReinitialize={true}
    >
      {({ errors, isSubmitting, dirty, resetForm }) => {
        const disableSave = !dirty || !isEmpty(errors);

        const handleClose = () => {
          resetForm();
          onClose();
        };

        return (
          <Form autoComplete="off">
            <Modal
              isVisible={isOpen}
              onClose={handleClose}
              title={
                <ModalTitle>
                  <FormattedMessage {...titleMessage} />
                </ModalTitle>
              }
              footer={[
                <If condition={!hideFooterCancelCta} key="CancelButton">
                  <Button
                    variant="ghost"
                    onClick={handleClose}
                    small={true}
                    type="button"
                    data-testid={testIds?.cancelCta}
                  >
                    <FormattedMessage {...cancelMessage} />
                  </Button>
                </If>,

                <Button
                  key="SaveButton"
                  disabled={disableSave || isSubmitting}
                  variant="solid-blue"
                  small={true}
                  type="submit"
                  data-testid={testIds?.submitCta}
                >
                  <Choose>
                    <When condition={isSubmitting}>
                      <FormattedMessage {...savingMessage} />
                    </When>
                    <Otherwise>
                      <FormattedMessage {...saveMessage} />
                    </Otherwise>
                  </Choose>
                </Button>,
              ]}
            >
              {children}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
});
